import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { mapBlogs } from "../../lib/utility";
import BlogRoll from "../../components/BlogRoll";

import Layout from "../../components/Layout";
import Header from "../../components/Header";
import ProductHero from "../../components/ProductHero";
import FindLO from "../../components/FindLO";
import ProductDetails from "../../components/ProductDetails";

import background from "../../images/armPageBg.jpg";
import icon from "./Icons_AdjustableRate_active.png";

import styles from "./styles.module.less";

const AdjustableRateMortgage = ({ data }) => {
    const header = <Header active={["adjustable-rate-mortgage"]} />;
    const { corpBlogs } = data;
    const { edges: corpBlobPosts = [] } = corpBlogs || {};
    const corporateBlogs = mapBlogs(corpBlobPosts);

    return (
        <Layout hasHero={true} header={header}>
            <Helmet>
                <title>Adjustable Rate Mortgage | Bay Equity Home Loans</title>
                <meta
                    name="description"
                    content="An adjustable-rate mortgage is a home loan where the interest rate changes periodically. Interested in getting an adjustable-rate home loan? Contact us today."
                />
                <meta
                    name="keywords"
                    content="adjustable rate mortgage,adjustable rate mortgage calculator,what is an adjustable rate mortgage,adjustable rate loan,adjustable mortgage rates,adjustable rate mortgage pros and cons,how does an adjustable rate mortgage work,adjustable rate mortgage vs fixed rate mortgage"
                />
            </Helmet>
            <ProductHero
                icon={icon}
                header={"Adjustable-Rate Mortgage"}
                subhead={subhead}
                customPhoto={background}
                alt="Adjustable Rate Mortage | Bay Equity Home Loans"
            />
            <ProductDetails
                title={"What is an adjustable-rate mortgage?"}
                border={true}>
                <p>
                    An adjustable-rate mortgage is a home loan where the
                    interest rate is fixed for a set period of time and then
                    changes periodically. Because it depends on many different
                    market factors, that change in rate may fluctuate—meaning
                    sometimes you’ll pay more for your adjustable-rate mortgage
                    and sometimes you’ll pay less.
                    <br />
                    <br />{" "}
                    <a
                        style={{ color: "#FD8A25" }}
                        href="/find-your-loan-officer">
                        Talk to one of our loan officers
                    </a>{" "}
                    to discuss the details of an adjustable-rate mortgage vs. a
                    fixed-rate mortgage and see which one is the right route to
                    get you home.
                </p>
            </ProductDetails>
            <ProductDetails
                title={"What are the benefits of an adjustable-rate mortgage?"}
                border={true}>
                <ul style={{ margin: 0 }}>
                    <li>
                        Enjoy lowest possible rates during the initial fixed
                        period
                    </li>
                    <li>
                        Qualify with a lower interest rate for more benefits
                    </li>
                    <li>Choose a fixed-start period of 5, 7 or 10 years</li>
                </ul>
            </ProductDetails>
            <ProductDetails
                title={
                    "What are the current rates for an adjustable-rate mortgage?"
                }
                border={true}>
                <p>
                    Adjustable-mortgage rates change all the time based on the
                    index tied to the particular mortgage. While we can’t
                    guarantee your specific percentage here and now,{" "}
                    <a
                        style={{ color: "#FD8A25" }}
                        href="/find-your-loan-officer">
                        talk to one of our loan officers
                    </a>{" "}
                    to lock in your exact interest rate.
                </p>
            </ProductDetails>
            <ProductDetails
                title={"How do I qualify for an adjustable-rate mortgage?"}
                border={false}>
                <p>
                    Qualifying for an adjustable-rate mortgage is simpler than
                    you might think. We’ll simply check a few things such as
                    your credit score before determining what loan program might
                    work best for you.
                </p>
                <p className={styles.productSub}>
                    Some adjustable-rate requirements
                </p>
                <ul className={styles.list}>
                    <li>Ideally, a credit score of 740 or higher</li>
                    <li>Suggested down payment between 5 and 20%</li>
                </ul>
            </ProductDetails>
            <FindLO />
            {corporateBlogs.length >= 3 && (
                <BlogRoll
                    corporateBlogs={corporateBlogs}
                    blogs={corporateBlogs}
                    showBlogs={true}
                    word="our"
                    url="adjustable-rate-mortgages"
                />
            )}
        </Layout>
    );
};

export default AdjustableRateMortgage;

const subhead = (
    <div>
        The rates may vary, but the benefits
        <br /> will always stay the same.
    </div>
);

export const pageQuery = graphql`
    query AdjustableRateMortgageQuery {
        corpBlogs: allBlog(
            sort: { fields: [date], order: DESC }
            filter: {
                type: { eq: "corporate" }
                tags: { in: ["Adjustable Rate Mortgages"] }
            }
        ) {
            edges {
                node {
                    id
                    html
                    slug
                    title
                    date
                    feature
                    categories
                    tags
                    image
                    author {
                        slug
                        profile {
                            name
                        }
                        gallery {
                            photo
                        }
                    }
                    date
                    feature
                }
            }
        }
    }
`;
